
button {
  background-color: rgb(211, 211, 251);
  padding: 0.6rem 0.8rem 0.5rem 0.8rem;
  border: 0;
  font-weight: bold;
  margin: 0.3rem 0 0.5rem 0;
  border-radius: 7px;
  border-bottom: 2px solid rgb(152, 152, 248);
}

.buttonDanger {
  background-color: rgb(255, 142, 142);
  border-bottom-color: red;
  color: white;
  font-weight: bold;
}

.btnSecondary {
  background-color: white;
  border: 1px solid #5f5f5f;
  border-bottom: 2px solid #aaaaaa;
}

.buttonRelation {
  border: 0;
  border-radius: 50%;
}

.buttonRelation:hover {
  background-color: rgb(211, 211, 251)!important;
} 

button:hover {
  cursor: pointer;
}

.documentContainer {
  padding: 2rem;
}
.tableContainer {
  background-color: #f7f7f7;
  
}

.tableContainerFrame {
  width: calc(75% - 6rem - 3px - 4rem);
  height: calc(100vh - 10rem);
  overflow: scroll;
  margin-bottom: 2rem;
}






.editContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2rem 6rem 2rem 3rem;
  border-left: 3px solid #5f5f5f;
  width: 25%;
  position: fixed;
  height: calc(100vh - 7rem);
  overflow-y: scroll;
}

.editContButtonsTop {
  display: flex;
  position: sticky;
  right: 0;
  top: 0;
  width: 480px;
  height: 50px;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  background-color: white;
}

.editTable, .editContButtonsTop {
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px dashed rgb(211, 211, 251);
}

.editTable {
  display: flex;
}

input{
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
}

.editTableFieldsArray {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}

.editFields {
  display: flex;
  padding-left: 1rem;
  border-left: 1px dashed #aaaaaa;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

.editFields div {
  
}

.editFields div input{
  
}

.fieldTitle {
  font-size: 12pt;
  margin-bottom: 0.2rem;
}








.bottomContainer {
  display: flex;
  width: calc(75% - 6rem - 3px - 4rem);
  gap: 2rem;
}

.bottomContainer div  {
  width: 50%;
}


.tableDrag {
  background-color: transparent;
  width: 120%;
  margin-left: -10%;
  font-size: 10pt;
  
}

.tableDrag {
  height: 25px;
  align-items: center;
}

.tableTable {
  background-color: rgb(255, 255, 255,0.9)!important;
  z-index: 500;
  min-width: 300px;
  padding: 0.8rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.tableHeader {
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  
}

.tableHeader h3 {
  margin: 0.2rem;
  
}

.tableInTable {
  width: 100%;
  
  display: flex;
  align-items: center;
}

.tableInTable div {
  height: 20px!important;
  
}

.tableInTable p {
  margin: 0;
  padding: 0;
  
}




.relationship-line-part {
  transition: stroke 0.3s, fill 0.3s;
}

.relationship-line:hover {
  stroke: yellow;
  fill: yellow;
}

.field-highlight {
  transition: background-color 0.3s;
}

.field-highlight:hover {
  background-color: lightgreen;
}



.popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgb(0, 208, 0);
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  
}